import React from 'react';
import './style.css';
export default function Footer() {
  return (
    <>
      <div className='footer'>
        <p>Copyright 2021 IoI Gaming</p>
      </div>
    </>
  );
}
